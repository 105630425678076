'use client'

import { RiAddLine, RiSparklingFill } from '@remixicon/react'
import { Button, Form, Input, Popover, Radio, Space } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { useRef, useState } from 'react'

import useDrawerState from '@/hooks/context/useDrawerState'
import useThreadState from '@/hooks/context/useThreadState'
import useAgents from '@/hooks/useAgents'
import useAuth from '@/hooks/useAuth'

import { generateUUID } from '@/utils'
import { generateSection } from '@/utils/thread'

interface AddSectionButtonProps {
  setEditing: (
    value: React.SetStateAction<{ [key: string]: boolean } | undefined>
  ) => void
}

const AddSectionButton: React.FC<AddSectionButtonProps> = ({ setEditing }) => {
  const { sections, setSections, questions, setSteps, currentStep } =
    useThreadState()
  const { selectedAgent } = useAgents()
  const { selectedConversation } = useDrawerState()
  const { user } = useAuth()
  const [form] = useForm()
  const [open, setOpen] = useState(false)
  const [generating, setGenerating] = useState(false)
  const [type, setType] = useState<'empty' | 'generate'>('empty')
  const textareaRef = useRef<any>(null)

  const handleAddSection = async () => {
    setOpen(false)
    if (!sections) {
      return
    }
    if (type === 'generate') {
      setGenerating(true)
      await generateSection(
        setSections,
        setSteps,
        form.getFieldValue('instructions'),
        questions,
        selectedAgent.id,
        selectedConversation,
        user,
        sections
      )
      setGenerating(false)
    } else {
      const id = generateUUID()
      setEditing((prev) => ({
        ...prev,
        [id]: true,
      }))
      setSections(
        ({ sections: prevSections, history: prevHistory, historyIndex }) => {
          const newSections = [
            ...(prevSections ?? []),
            {
              id,
              title: '',
              text: '',
              subsections: [],
            },
          ]
          const newHistory = [
            ...(prevHistory.slice(0, (historyIndex ?? 0) + 1) ?? []),
            newSections,
          ]
          setSteps((prevSteps) => ({
            ...prevSteps,
            [currentStep]: {
              ...prevSteps[currentStep],
              sections: newSections,
            },
          }))
          return {
            sections: newSections,
            history: newHistory,
            historyIndex: newHistory.length - 1,
          }
        }
      )
    }
    form.setFieldValue('instructions', '')
    form.setFieldValue('type', 'empty')
    setType('empty')
  }

  return (
    <Popover
      trigger='click'
      overlayClassName='w-full max-w-md'
      open={open}
      onOpenChange={setOpen}
      content={
        <Form
          form={form}
          autoComplete='off'
          className='flex w-full flex-col items-end'
          layout='vertical'
          initialValues={{ type: 'empty' }}
          onFinish={handleAddSection}
        >
          <Form.Item name='type' className='w-full'>
            <Radio.Group value={type} onChange={(e) => setType(e.target.value)}>
              <Space direction='vertical'>
                <Radio value='empty'>Add an empty section at the end</Radio>
                <Radio value='generate'>
                  Generate a new section following the instructions
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {type === 'generate' && (
            <Form.Item
              name='instructions'
              rules={[{ type: 'string' }]}
              className='w-full'
            >
              <Input.TextArea
                ref={textareaRef}
                size='small'
                className='max-w-64 origin-left overflow-hidden transition-all'
                placeholder='Provide specific instructions on how to generate this section.'
                rows={4}
                required
              />
            </Form.Item>
          )}
          <Form.Item className='m-0'>
            <Button
              className='flex items-center'
              htmlType='submit'
              type='primary'
              icon={
                type === 'empty' ? (
                  <RiAddLine className='size-5' />
                ) : (
                  <RiSparklingFill className='size-5' />
                )
              }
            >
              Add
            </Button>
          </Form.Item>
        </Form>
      }
    >
      <Button
        className='w-fit self-end'
        icon={<RiAddLine className='size-5' />}
        loading={generating}
      >
        <span className='!hidden lg:!flex'>
          {generating ? 'Generating...' : 'Add Section'}
        </span>
      </Button>
    </Popover>
  )
}

export default AddSectionButton
