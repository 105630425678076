import { dateFormat, datetimeFormat } from '@/branding-config'

export const getPatentableInnovationsPrompt = (additionalInfo?: string) =>
  `Identify and Elaborate on Patentable Innovations
Now provide me with a list of available permutations and improvements to my idea would be patentable this year that are a fit for my company and my goals. List each patents specific patentable characteristics as well as your next steps to draft and file a provisional patent to preserve the filing date, and a utility patent to fully protect the IP. Be sure to include relevant new innovations for the industry in which I operate. Be as extremely specific including all data you can find about each idea and prior art search. Please include scholarly citations.

<TOOL>
USE SERPAPI AND SEMANTICSCHOLAR TOOLS TO SEARCH FOR RELATED INFORMATION (patents and scientific articles)
USE COMMAS TO SEPARATE EACH CONCEPT OF THE QUERY.
</TOOL>

${additionalInfo ? `<IMPORTANT>${additionalInfo}</IMPORTANT>` : ''}

<IMPORTANT>
RESPOND IN JSON FORMAT. No markdown or HTML formatting. No additional text before JSON. ONLY JSON!!! The response should be successfully parsed by JSON.parse() function.
</IMPORTANT>
JSON format should be as follows:
{
  "innovations": [
    {
      "name": "",
      "properties": [
        {
          "property": "Patentable Characteristics",
          "value": "",
        },
        ... a lot of other relevant properties ...
      ]
    }
  ]
}

Innovations report should be very long and very detailed.

USE INLINE CITATIONS IN TEXT FOR ALL FACTS, DATA, AND INFORMATION THAT IS RETRIEVED FROM CONTEXT DOCUMENTS, MESSAGE HISTORY OR WEB SEARCHES!!!
THERE MUST BE AT LEAST 1 CITATION PER PARAGRAPH.
FOR DATES ALWAYS USE AMERICAN DATE FORMAT: ${dateFormat}
FOR DATE TIME ALWAYS USE AMERICAN DATE TIME FORMAT: ${datetimeFormat}`
