export const feedbackPrompt = (values: { [x: string]: string }) =>
  `These are my replies to the 5 questions you asked me:
1. How satisfied are you with the Deepinvent assistance you received?
${values.feedback1}

2. How would you rate the quality of the patent Deepinvent produced?
${values.feedback2}

3. Compared to normal non-assisted patent writing, how would you rate the experience of using Deepinvent for writing a patent application?
${values.feedback3}

4. What other comments, concerns, or reflections do you have about Deepinvent?
${values.feedback4 ?? '-'}

5. What other questions should be asked of users of Deepinvent during onboarding to provide even better results?
${values.feedback5 ?? '-'}      

Respond just with: Thank you for your feedback. We will take it into account.`
