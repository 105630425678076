import { dateFormat, datetimeFormat } from '@/branding-config'

export const applicationGuidanceEnhancePrompt = (
  guidanceInfo: string,
  enhanceInstructions: string
) =>
  `CONTEXT:

${guidanceInfo}
  
Enhance this patent application guidance with more data.

${enhanceInstructions ? `<IMPORTANT>You must follow these specific guidelines for enhancement above all else: ${enhanceInstructions}</IMPORTANT>` : ''}

The report should be very long and very detailed.
FOR DATES ALWAYS USE AMERICAN DATE FORMAT: ${dateFormat}
FOR DATE TIME ALWAYS USE AMERICAN DATE TIME FORMAT: ${datetimeFormat}
YOU MUST RESPOND IN MARKDOWN FORMAT.`
