import { useContext } from 'react'

import { ThreadContext } from '@/context/thread/thread.context'

const useThreadState = () => {
  const context = useContext(ThreadContext)
  if (!Object.keys(ThreadContext).length) {
    throw new Error('ThreadContext must be used within its provider')
  }
  return context
}

export default useThreadState
