import { dateFormat, datetimeFormat } from '@/branding-config'

import { sectionToString } from '..'

import { Section, Subsection } from '@/types/document'
import { EnhanceType } from '@/types/thread'

const additionalRules = `ADDITIONAL RULES:
- DO NOT SHORTEN ANY TEXT.
- INSTEAD OF CHARACTER "\\n" ALWAYS USE AN ACTUAL NEW LINE.
- DO NOT USE DOUBLE PARENTHESES.
- DO NOT USE BACKSLASHES.
- THE TITLE OF THE SUBSECTION MUST BE IN PROPERTY "TITLE" AND MUST NOT BE INCLUDED IN PROPERTY "TEXT".
- FOR DATES ALWAYS USE AMERICAN DATE FORMAT: ${dateFormat}
- FOR DATE TIME ALWAYS USE AMERICAN DATE TIME FORMAT: ${datetimeFormat}
- RESPOND IN JSON.`

export const enhanceSubsectionPrompt = (
  subsection: Subsection,
  type: EnhanceType,
  sections?: Section[],
  enhanceInstructions?: string
) => {
  if (type === EnhanceType.LONGER) {
    return `RESPOND IN JSON.
CONTEXT:
${sections?.map((s) => sectionToString(s)).join('\n')}


TASK:
Your goal is to enhance the following proposal subsection by expanding on its content, making it significantly more detailed and thorough. The resulting text should be more elaborate and descriptive.

${sectionToString(subsection)}


RESPONSE FORMAT:
Return the enhanced subsection in valid JSON with the following structure:
{
  "title": "Subsection Title without 'Enhanced', 'Improved', 'Expanded' or similar adjectives that would show it was enhanced",
  "text": "Expanded and very long and detailed subsection text in markdown, AT LEAST 5000 CHARACTERS LONG",
}


ENHANCEMENT INSTRUCTIONS:

${enhanceInstructions ? `<IMPORTANT>You must follow these specific guidelines for enhancement above all else: ${enhanceInstructions}</IMPORTANT>` : ''}

- Ensure that the enhanced text is very long, AT LEAST TWICE AS LONG as the original. 2 times more words than the original text.
- Expand on every idea, providing additional context, explanation, and examples where relevant.
- Use the same tone and style as the original text. Use the same structure and formatting. You must mimic the original author's style.
- Add more very detailed and specific information to the section.
- Add more numbers and specific timelines and budget numbers.
- Ensure that titles are clear, concise, and descriptive. Title should be in property "title" and not be included in property "text". Do not add "enhanced", "improved", "expanded" or similar adjectives to the title!
- Always return subsection text in valid markdown format. INSTEAD OF CHARACTER "\\n" ALWAYS USE AN ACTUAL NEW LINE.
- When listing items in subsection text use numbered or bulleted lists in markdown format where appropriate.

${additionalRules}`
  } else if (type === EnhanceType.SHORTER) {
    return `RESPOND IN JSON.
CONTEXT:
${sections?.map((s) => sectionToString(s)).join('\n')}


TASK:
Your goal is to enhance the following proposal subsection by shortening its content, making it significantly more concise while keeping all important information. The resulting text should be more concise and straight to the point.

${sectionToString(subsection)}


RESPONSE FORMAT:
Return the enhanced subsection in valid JSON with the following structure:
{
  "title": "Subsection Title without 'Enhanced', 'Improved', 'Expanded' or similar adjectives that would show it was enhanced",
  "text": "Shortened and very concise and detailed subsection text in markdown",
}


ENHANCEMENT INSTRUCTIONS:

${enhanceInstructions ? `<IMPORTANT>You must follow these specific guidelines for enhancement above all else: ${enhanceInstructions}</IMPORTANT>` : ''}

- Ensure that the enhanced text is shorter and more concise than the original.
- Use the same tone and style as the original text. Use the same structure and formatting. You must mimic the original author's style.
- Ensure that titles are clear, concise, and descriptive. Title should be in property "title" and not be included in property "text". Do not add "enhanced", "improved", "expanded" or similar adjectives to the title!
- Always return subsection text in valid markdown format. INSTEAD OF CHARACTER "\\n" ALWAYS USE AN ACTUAL NEW LINE.
- When listing items in subsection text use numbered or bulleted lists in markdown format where appropriate.

${additionalRules}`
  } else {
    return `RESPOND IN JSON.
CONTEXT:
${sections?.map((s) => sectionToString(s)).join('\n')}


TASK:
Your goal is to enhance the following proposal subsection by expanding on its content, making it significantly more detailed and thorough. The resulting text should be more elaborate and descriptive. You must use the search and scrape function to gather new data and information to use in the enhanced text.

${sectionToString(subsection)}


RESPONSE FORMAT:
Return the enhanced subsection in valid JSON with the following structure:
{
  "title": "Subsection Title without 'Enhanced', 'Improved', 'Expanded' or similar adjectives that would show it was enhanced",
  "text": "Expanded and very long and detailed subsection text in markdown, AT LEAST 5000 CHARACTERS LONG",
}


ENHANCEMENT INSTRUCTIONS:

${enhanceInstructions ? `<IMPORTANT>You must follow these specific guidelines for enhancement above all else: ${enhanceInstructions}</IMPORTANT>` : ''}

- CONDUCT WEB SEARCHES AND ACCESS REAL-TIME DATA DIRECTLY WITH THE SEARCH AND SCRAPE FUNCTION TO GATHER NEW DATA AND INFORMATION TO USE IN THE ENHANCED TEXT.
- Ensure that the enhanced text is very long, AT LEAST TWICE AS LONG as the original. 2 times more words than the original text.
- Expand on every idea, providing additional context, explanation, and examples where relevant.
- Use the same tone and style as the original text. Use the same structure and formatting. You must mimic the original author's style.
- Add more very detailed and specific information to the section.
- Add more numbers and specific timelines and budget numbers.
- Ensure that titles are clear, concise, and descriptive. Title should be in property "title" and not be included in property "text". Do not add "enhanced", "improved", "expanded" or similar adjectives to the title!
- Always return subsection text in valid markdown format. INSTEAD OF CHARACTER "\\n" ALWAYS USE AN ACTUAL NEW LINE.
- When listing items in subsection text use numbered or bulleted lists in markdown format where appropriate.

${additionalRules}`
  }
}
