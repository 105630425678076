import { dateFormat, datetimeFormat } from '@/branding-config'

export const ideaEnhancePrompt = (
  ideaInfo: string,
  enhanceInstructions: string
) =>
  `CONTEXT:

${ideaInfo}
  
Enhance my idea with more data.

${enhanceInstructions ? `<IMPORTANT>You must follow these specific guidelines for enhancement above all else: ${enhanceInstructions}</IMPORTANT>` : ''}

Research my idea and list in detail the information you learned about my idea. USE SEARCHANDSCRAPE TOOL TO RESEARCH MY IDEA.

Provide a detailed report with the information you learned about the idea.
The report should be very long and very detailed.
Ensure that your response is filled WITH FACTS, DATA, FIGURES, FINANCIALS, AND NUMBERS.
FOR DATES ALWAYS USE AMERICAN DATE FORMAT: ${dateFormat}
FOR DATE TIME ALWAYS USE AMERICAN DATE TIME FORMAT: ${datetimeFormat}
YOU MUST RESPOND IN MARKDOWN FORMAT.`
