import { dateFormat, datetimeFormat } from '@/branding-config'

export const companyEnhancePrompt = (
  companyInfo: string,
  enhanceInstructions: string
) =>
  `CONTEXT:

${companyInfo}
  
INSTRUCTIONS:

${enhanceInstructions ? `<IMPORTANT>You must follow these specific guidelines for enhancement above all else: ${enhanceInstructions}</IMPORTANT>` : ''}

Enhance company information with more data.
Research my company with the search and scrape function and context and list in detail the information you learned about my company.
Present me with a very detailed description of my company, including the company's address, number of employees, annual revenue, and any additional information you can find.
Ensure that your response is filled WITH FACTS, DATA, FIGURES, FINANCIALS, AND NUMBERS.
Include precise and detailed financial information about my company.
FOR DATES ALWAYS USE AMERICAN DATE FORMAT: ${dateFormat}
FOR DATE TIME ALWAYS USE AMERICAN DATE TIME FORMAT: ${datetimeFormat}`
