'use client'

import { RiSendPlane2Line, RiSparkling2Fill } from '@remixicon/react'
import { Form } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useDrawerState from '@/hooks/context/useDrawerState'
import useThreadState from '@/hooks/context/useThreadState'
import useAgents from '@/hooks/useAgents'
import useAuth from '@/hooks/useAuth'

import FeedbackStep from '@/components/Matcher/Steps/FeedbackStep'

import { stepsData } from '@/constants/matcher'
import { getAnswer, getDrafts, stopAnswering } from '@/service/Chatbot'
import { generateUUID } from '@/utils'
import { ideaPrompt } from '@/utils/prompts'

import DraftEditor from './DraftEditor'
import StepHeader from './StepHeader'
import ApplicationGuidanceStep from './Steps/ApplicationGuidanceStep'
import BestPatentOptionStep from './Steps/BestPatentOptionStep'
import CompanyStep from './Steps/CompanyStep'
import IdeaStep from './Steps/IdeaStep'
import SelectInnovationStep from './Steps/SelectInnovationStep'
import TextAreaWithUpload from './TextAreaWithUpload'
import LoginModal from '../LoginModal'
import VideoBackground from '../VideoBackground'

import { IQuestion } from '@/types/chatbot'

interface MatcherProps {
  ideaRef: React.MutableRefObject<string>
}

const Matcher: React.FC<MatcherProps> = ({ ideaRef }) => {
  const { agents, selectedAgent } = useAgents()
  const { user } = useAuth()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const [progressException, setProgressException] = useState(false)
  const { selectedConversation, setSelectedConversation, mutateConversations } =
    useDrawerState()
  const {
    currentStep,
    questions,
    steps,
    setQuestions,
    setCurrentStep,
    setSections,
    setSteps,
  } = useThreadState()
  const [form] = Form.useForm()
  const [loginModalOpen, setLoginModalOpen] = useState(false)

  useEffect(() => {
    stopAnswering(true, setLoading)
    form.resetFields()
  }, [selectedConversation])

  useEffect(() => {
    form.setFieldsValue({ idea: ideaRef.current })
  }, [user])

  const getInnovations = async (
    additionalInfo?: string,
    tmpQuestions?: IQuestion[]
  ) => {
    setLoading(true)
    try {
      await getDraftsWrapper(additionalInfo, tmpQuestions)
    } catch (e) {
      setProgressException(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!loading && currentStep === 1) {
      mutateConversations()
    }
  }, [loading])

  const getDraftsWrapper = async (
    question?: string,
    tmpQuestions?: IQuestion[]
  ) => {
    if (!selectedAgent || !agents || !selectedConversation) return
    await getDrafts(
      true,
      question && question !== '' ? question : ' ',
      selectedAgent.id,
      tmpQuestions ?? questions,
      setLoading,
      setQuestions,
      t,
      selectedConversation
    )
  }

  const retry = async (additionalInfo: string) => {
    setProgressException(false)
    let newQuestions = questions
    if (steps[currentStep]?.numQuestions) {
      newQuestions = questions.slice(0, -steps[currentStep].numQuestions)
    }
    setQuestions(newQuestions)
    await getInnovations(additionalInfo, newQuestions)
  }

  const getAnswerWrapper = async (
    question: string,
    streaming: boolean,
    json?: boolean
  ) => {
    if (!selectedAgent || !agents || !setQuestions) return
    await getAnswer(
      true,
      question,
      selectedAgent.id,
      questions,
      agents,
      setLoading,
      setQuestions,
      t,
      false,
      user?.email,
      true,
      selectedConversation,
      streaming,
      false,
      false,
      undefined,
      json
    )
  }

  const goBack = () => {
    if (steps[currentStep]?.numQuestions) {
      setQuestions(questions.slice(0, -steps[currentStep].numQuestions))
    }
    setLoading(false)
    setSteps({
      ...steps,
      [currentStep]: undefined,
    })

    if (currentStep === 1) {
      setCurrentStep(0)
      setProgressException(false)
      setQuestions([])
      setSelectedConversation(generateUUID())
    } else {
      if (currentStep === 3) {
        setProgressException(false)
        if (steps[currentStep].previousStep) {
          setCurrentStep(steps[currentStep].previousStep)
          return
        }
      } else if (currentStep === 5) {
        setSections({
          sections: [],
          history: [],
          historyIndex: 0,
        })
      }
      setCurrentStep(currentStep - 1)
    }
  }

  const beginWithIdea = async (values: { [x: string]: string }) => {
    if (!user) {
      setLoginModalOpen(true)
      return
    }
    setCurrentStep(1)
    await getAnswerWrapper(ideaPrompt(values.idea ?? ideaRef.current), true)
  }

  if (currentStep !== 0 && !!steps) {
    return (
      <div className='flex size-full flex-col'>
        <StepHeader
          title={stepsData[currentStep]?.title}
          tooltip={stepsData[currentStep]?.tooltip}
        />
        {currentStep === 1 ? (
          <IdeaStep
            getInnovations={getInnovations}
            getAnswerWrapper={getAnswerWrapper}
            loading={loading}
            goBack={goBack}
          />
        ) : currentStep === 2 ? (
          <CompanyStep
            getInnovations={getInnovations}
            getAnswerWrapper={getAnswerWrapper}
            loading={loading}
            goBack={goBack}
          />
        ) : currentStep === 3 ? (
          <SelectInnovationStep
            getAnswerWrapper={getAnswerWrapper}
            loading={loading}
            setLoading={setLoading}
            retry={retry}
            progressException={progressException}
            setProgressException={setProgressException}
            goBack={goBack}
          />
        ) : currentStep === 4 ? (
          <BestPatentOptionStep
            getAnswerWrapper={getAnswerWrapper}
            loading={loading}
            goBack={goBack}
          />
        ) : currentStep === 5 ? (
          <DraftEditor
            loading={loading}
            goBack={goBack}
            getAnswerWrapper={getAnswerWrapper}
          />
        ) : currentStep === 6 ? (
          <ApplicationGuidanceStep
            loading={loading}
            goBack={goBack}
            getAnswerWrapper={getAnswerWrapper}
          />
        ) : currentStep === 7 ? (
          <FeedbackStep
            getAnswerWrapper={getAnswerWrapper}
            loading={loading}
            goBack={goBack}
          />
        ) : null}
      </div>
    )
  }

  return (
    <>
      <VideoBackground />
      <div className='flex size-full flex-col items-center justify-center gap-4 px-2 sm:gap-6'>
        <div className='z-10 flex flex-col text-primary sm:gap-2'>
          <h1 className='text-2xl sm:text-5xl'>
            {t('index-title-quote')}
            <span className='inline-block align-middle'>
              <RiSparkling2Fill className='size-5 sm:size-9' />
            </span>
          </h1>
          <h3 className='text-xl brightness-90 sm:text-3xl'>
            {t('index-title')}
          </h3>
        </div>
        <div className='z-10 w-full max-w-screen-md'>
          <TextAreaWithUpload
            inputRef={ideaRef}
            onFinish={beginWithIdea}
            finishTooltip="Let's begin"
            finishIcon={<RiSendPlane2Line className='size-5' />}
            finishType='primary'
            formErrorMessage='What is your idea?'
            placeholder='What is your idea?'
            uploadTooltip='Upload files about your idea'
            classname='max-w-screen-md'
          />
        </div>
      </div>
      <LoginModal open={loginModalOpen} setOpen={setLoginModalOpen} />
    </>
  )
}

export default Matcher
