import { Card } from 'antd'

import { markdown } from '@/utils'
import { cn } from '@/utils/clsx'

interface SelectInnovationCardProps {
  index: number
  innovation: {
    name: string
    properties: { property: string; value: string }[]
  }
  selectedInnovation?: number
  setSelectedInnovation: (selectedInnovation?: number) => void
}

const SelectInnovationCard: React.FC<SelectInnovationCardProps> = ({
  index,
  innovation,
  selectedInnovation,
  setSelectedInnovation,
}) => {
  return (
    <Card
      className={cn(
        'bg-surface dark:bg-dark-surface cursor-pointer',
        index === selectedInnovation
          ? 'bg-primary/40 dark:bg-dark-primary/40'
          : ''
      )}
      onClick={() =>
        selectedInnovation === index
          ? setSelectedInnovation(undefined)
          : setSelectedInnovation(index)
      }
    >
      <div className='flex flex-col gap-2'>
        <div className='text-lg font-bold'>{innovation.name}</div>
        <ul className='list-disc'>
          {innovation.properties.map(({ property, value }, index) => (
            <li key={index}>
              <div className='font-bold'>{property}:</div>{' '}
              <p>{markdown(value)}</p>
            </li>
          ))}
        </ul>
      </div>
    </Card>
  )
}

export default SelectInnovationCard
