import { IQuestion } from './chatbot'

export type Thread = {
  questions: IQuestion[]
  steps: { [key: string]: any }
  currentStep: number
}

export enum EnhanceType {
  LONGER = 'longer',
  SHORTER = 'shorter',
  DATA = 'data',
  CITE = 'cite',
}

export type Innovation = {
  name: string
  properties: { property: string; value: string }[]
}
