'use client'

import { DragDropContext, Droppable } from '@hello-pangea/dnd'
import { useState } from 'react'

import useThreadState from '@/hooks/context/useThreadState'

import SectionContainer from './SectionContainer'
import SubsectionsDragDrop from './SubsectionsDragDrop'

import { Section } from '@/types/document'

interface SectionsDragDropProps {
  enhancing?: { [key: string]: boolean }
  editing?: { [key: string]: boolean }
  collapsed?: { [key: string]: boolean }
  setEnhancing: (
    value: React.SetStateAction<{ [key: string]: boolean } | undefined>
  ) => void
  setEditing: (
    value: React.SetStateAction<{ [key: string]: boolean } | undefined>
  ) => void
  setCollapsed: (
    value: React.SetStateAction<{ [key: string]: boolean } | undefined>
  ) => void
}

const SectionsDragDrop: React.FC<SectionsDragDropProps> = ({
  enhancing,
  editing,
  collapsed,
  setEnhancing,
  setEditing,
  setCollapsed,
}) => {
  const { sections, setSections, setSteps, currentStep } = useThreadState()
  const [currentlyDraggedItem, setCurrentlyDraggedItem] = useState<string>()

  const reorder = (list: Section[], startIndex: number, endIndex?: number) => {
    if (endIndex === undefined) {
      return list
    }
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    if (removed) {
      result.splice(endIndex, 0, removed)
    }
    return result
  }

  const onBeforeCapture = (start: { draggableId: string }) => {
    // Collapse the section that is being dragged
    const draggedSection = sections?.find(
      (section) => section.id === start.draggableId
    )
    if (draggedSection?.id && !collapsed?.[draggedSection.id]) {
      setCurrentlyDraggedItem(draggedSection?.id)
    }
    if (draggedSection) {
      setCollapsed((prev) => ({
        ...prev,
        [draggedSection.id]: true,
      }))
    }
  }

  const onDragEnd = (result: {
    destination: { index: number } | null
    source: { index: number }
  }) => {
    if (!result.destination || !sections) {
      return
    }

    if (currentlyDraggedItem) {
      setCollapsed((prev) => ({
        ...prev,
        [currentlyDraggedItem]: false,
      }))
      setCurrentlyDraggedItem(undefined)
    }

    setSections(
      ({ sections: prevSections, history: prevHistory, historyIndex }) => {
        if (!prevSections) {
          return { sections: prevSections, history: prevHistory, historyIndex }
        }
        const newSections = reorder(
          prevSections,
          result.source.index,
          result.destination?.index
        )
        const newHistory = [
          ...(prevHistory.slice(0, (historyIndex ?? 0) + 1) ?? []),
          newSections,
        ]
        setSteps((prevSteps) => ({
          ...prevSteps,
          [currentStep]: {
            ...prevSteps[currentStep],
            sections: newSections,
          },
        }))
        return {
          sections: newSections,
          history: newHistory,
          historyIndex: newHistory.length - 1,
        }
      }
    )
  }

  return (
    <DragDropContext onBeforeCapture={onBeforeCapture} onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className='flex flex-col gap-3'
          >
            {sections?.map((section, index) => (
              <SectionContainer
                key={section.id}
                section={section}
                index={index}
                enhancing={enhancing?.[section.id]}
                editing={editing?.[section.id]}
                collapsed={collapsed?.[section.id]}
                setEnhancing={(value) =>
                  setEnhancing((prev) => ({ ...prev, [section.id]: !!value }))
                }
                setEditing={(value) =>
                  setEditing((prev) => ({ ...prev, [section.id]: !!value }))
                }
                setEditingSubsection={(subsectionId, value) =>
                  setEditing((prev) => ({ ...prev, [subsectionId]: !!value }))
                }
                setCollapsed={(value) =>
                  setCollapsed((prev) => ({ ...prev, [section.id]: !!value }))
                }
              >
                <>
                  {!collapsed?.[section.id] && (
                    <SubsectionsDragDrop
                      section={section}
                      enhancing={enhancing}
                      setEnhancing={setEnhancing}
                      editing={editing}
                      setEditing={setEditing}
                      collapsed={collapsed}
                      setCollapsed={setCollapsed}
                      currentlyDraggedItem={currentlyDraggedItem}
                      setCurrentlyDraggedItem={setCurrentlyDraggedItem}
                    />
                  )}
                </>
              </SectionContainer>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default SectionsDragDrop
