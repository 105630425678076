export const stepsData: { [key: string]: { title: string; tooltip?: string } } =
  {
    '1': {
      title: 'Idea Research',
    },
    '2': {
      title: 'Company Research',
    },
    '3': {
      title: 'Select Patentable Innovation',
    },
    '4': {
      title: 'Best Patent Option',
    },
    '5': {
      title: 'Edit Patent Application Draft',
    },
    '6': {
      title: 'Application Guidance',
    },
    '7': {
      title: 'Feedback',
    },
  }
