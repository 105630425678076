import { dateFormat, datetimeFormat } from '@/branding-config'

export const bestPatentPrompt = (selectedInnovation?: string) =>
  `${selectedInnovation ? `User selected patentable innovation: ${selectedInnovation}` : 'Review these patent options carefully and select one particular patent that aligns most closely with my company and what we do.'}

Provide the real links to the relevant prior art intellectual property including the patent filings. Then list out a summary of criteria for successfully differentiating our idea from these previous patents, and how our new patent specifically meets each of these criteria being extremely specific including facts and data both about the intellectual property and the implications for the company and/or industry. List out the typical timeline for the patent to be reviewed and approved based on available data on the web. Use scholarly citations.
Analyze Prior Art related to these new innovative synthesized ideas and Select the Best paths to patents that are non obvious, novel, and relevant to our company's goals. Please include scholarly citations of patents and other literature.
USE SERPAPI TOOL TO RESEARCH PATENTS DATABASE.

FOR DATES ALWAYS USE AMERICAN DATE FORMAT: ${dateFormat}
FOR DATE TIME ALWAYS USE AMERICAN DATE TIME FORMAT: ${datetimeFormat}
YOU MUST RESPOND IN MARKDOWN FORMAT.
USE INLINE CITATIONS IN TEXT FOR ALL FACTS, DATA, AND INFORMATION THAT IS RETRIEVED FROM CONTEXT DOCUMENTS, MESSAGE HISTORY OR WEB SEARCHES!!!
THERE MUST BE AT LEAST 1 CITATION PER PARAGRAPH.`
