'use client'

import { RiLineChartLine, RiSparklingFill } from '@remixicon/react'
import { Button, Form, Input, Popover, Tooltip } from 'antd'
import axios from 'axios'
import { useRef, useState } from 'react'

import useThreadState from '@/hooks/context/useThreadState'
import useAgents from '@/hooks/useAgents'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'
import { getContext } from '@/service/Chatbot'
import { generateGraphPrompt } from '@/utils/prompts'
import { createTmpMessage, updateGraphsOfSection } from '@/utils/thread'

import { IQuestion } from '@/types/chatbot'
import { Section, Subsection } from '@/types/document'

interface EnhanceButtonProps {
  section: Section | Subsection
  isSubsection?: boolean
}

const GraphButton: React.FC<EnhanceButtonProps> = ({
  section,
  isSubsection,
}) => {
  const { questions, sections, setSections, setSteps } = useThreadState()
  const { selectedAgent } = useAgents()
  const [open, setOpen] = useState(false)
  const [graphGenerating, setGraphGenerating] = useState(false)
  const [graphInstructions, setGraphInstructions] = useState('')
  const textareaRef = useRef<any>(null)

  const postGraphApi = async (question: string, questions: IQuestion[]) => {
    try {
      const { messages } = getContext(questions)

      const config = {
        method: 'post',
        withCredentials: true,
        ...configHeader,
        data: JSON.stringify({
          question,
          messages,
        }),
      }

      const res = await axios(`${API_URL}/v2/chatbot/graph`, config)

      return res.data
    } catch (error) {
      console.error(error)
    }
  }

  const generateGraph = async () => {
    setOpen(false)
    setGraphGenerating(true)
    const prompt = generateGraphPrompt(section, sections, graphInstructions)
    const tmpQuestion: IQuestion = createTmpMessage(prompt, selectedAgent.id)
    try {
      const res: { graph: string } = await postGraphApi(prompt, [
        ...(questions ?? []),
        tmpQuestion,
      ])
      updateGraphsOfSection(
        setSections,
        setSteps,
        isSubsection,
        section,
        res.graph
      )
    } catch (error) {
      console.error(error)
    }
    setGraphGenerating(false)
    setGraphInstructions('')
  }

  return (
    <div className='flex gap-2'>
      <Popover
        trigger='click'
        overlayClassName='w-full max-w-md'
        open={open}
        onOpenChange={setOpen}
        content={
          <Form
            autoComplete='off'
            className='flex w-full flex-col items-end'
            layout='vertical'
          >
            <Form.Item
              name='additionalInfo'
              rules={[{ type: 'string' }]}
              className='w-full'
            >
              <Input.TextArea
                ref={textareaRef}
                value={graphInstructions}
                size='small'
                className='max-w-64 origin-left overflow-hidden transition-all'
                placeholder='Optional: provide specific instruction on how to generate the graph'
                onChange={(e) => setGraphInstructions(e.target.value)}
                rows={4}
              />
            </Form.Item>

            <Form.Item className='m-0'>
              <Button
                className='flex items-center'
                htmlType='submit'
                type='primary'
                onClick={() => {
                  generateGraph()
                }}
                icon={<RiSparklingFill className='size-5' />}
              >
                Generate
              </Button>
            </Form.Item>
          </Form>
        }
      >
        <Tooltip title='Generate graph'>
          <Button
            className='w-fit'
            icon={<RiLineChartLine className='size-5' />}
            loading={graphGenerating}
            type='text'
          >
            {graphGenerating ? (
              <span className='!hidden md:!block'>Generating graph...</span>
            ) : null}
          </Button>
        </Tooltip>
      </Popover>
    </div>
  )
}

export default GraphButton
