'use client'

import { DragDropContext, Droppable } from '@hello-pangea/dnd'

import useThreadState from '@/hooks/context/useThreadState'

import SubsectionContainer from './SubsectionContainer'

import { Section, Subsection } from '@/types/document'

interface SubsectionsDragDropProps {
  section: Section
  enhancing?: { [key: string]: boolean }
  editing?: { [key: string]: boolean }
  collapsed?: { [key: string]: boolean }
  currentlyDraggedItem?: string
  setEnhancing: (
    value: React.SetStateAction<{ [key: string]: boolean } | undefined>
  ) => void
  setEditing: (
    value: React.SetStateAction<{ [key: string]: boolean } | undefined>
  ) => void
  setCollapsed: (
    value: React.SetStateAction<{ [key: string]: boolean } | undefined>
  ) => void
  setCurrentlyDraggedItem: (
    value: React.SetStateAction<string | undefined>
  ) => void
}

const SubsectionsDragDrop: React.FC<SubsectionsDragDropProps> = ({
  section,
  enhancing,
  editing,
  collapsed,
  currentlyDraggedItem,
  setEnhancing,
  setEditing,
  setCollapsed,
  setCurrentlyDraggedItem,
}) => {
  const { sections, setSections, setSteps, currentStep } = useThreadState()

  const reorderSubsections = (
    list: Subsection[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    if (removed) {
      result.splice(endIndex, 0, removed)
    }
    return result
  }

  const onBeforeCaptureSubsection = (start: { draggableId: string }) => {
    // Collapse the subsection that is being dragged
    const draggedSubsection = sections?.reduce(
      (acc: Section | undefined, section: Section) => {
        if (section.subsections) {
          const subsection = section.subsections.find(
            (sub) => sub.id === start.draggableId
          )
          if (subsection) {
            return subsection
          }
        }
        return acc
      },
      undefined
    )
    if (draggedSubsection && !collapsed?.[draggedSubsection.id]) {
      setCurrentlyDraggedItem(draggedSubsection?.id)
    }
    if (draggedSubsection) {
      setCollapsed((prev) => ({
        ...prev,
        [draggedSubsection.id]: true,
      }))
    }
  }

  const onDragEndSubsections = (result: {
    destination: { index: number } | null
    source: { index: number }
  }) => {
    if (!result.destination || !sections) {
      return
    }

    if (currentlyDraggedItem) {
      setCollapsed((prev) => ({
        ...prev,
        [currentlyDraggedItem]: false,
      }))
      setCurrentlyDraggedItem(undefined)
    }

    setSections(
      ({ sections: prevSections, history: prevHistory, historyIndex }) => {
        const newSections = prevSections?.map((s) => {
          if (s.subsections) {
            const reorderedSubsections = reorderSubsections(
              s.subsections,
              result.source.index,
              result.destination?.index ?? 0
            )
            return {
              ...s,
              subsections: reorderedSubsections,
            }
          }
          return s
        })
        let newHistory = prevHistory
        if (newSections) {
          newHistory = [
            ...(prevHistory.slice(0, (historyIndex ?? 0) + 1) ?? []),
            newSections,
          ]
        }
        setSteps((prevSteps) => ({
          ...prevSteps,
          [currentStep]: {
            ...prevSteps[currentStep],
            sections: newSections,
          },
        }))
        return {
          sections: newSections,
          history: newHistory,
          historyIndex: newHistory.length - 1,
        }
      }
    )
  }

  return (
    <DragDropContext
      onBeforeCapture={onBeforeCaptureSubsection}
      onDragEnd={onDragEndSubsections}
    >
      <Droppable droppableId={`${section.id}-subsectionsDroppable`}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className='flex flex-col gap-3'
          >
            {section.subsections?.map((subsection, index: number) => (
              <SubsectionContainer
                key={subsection.id}
                section={section}
                subsection={subsection}
                index={index}
                enhancing={enhancing?.[subsection.id]}
                editing={editing?.[subsection.id]}
                collapsed={collapsed?.[subsection.id]}
                setEnhancing={(value) =>
                  setEnhancing((prev) => ({
                    ...prev,
                    [subsection.id]: !!value,
                  }))
                }
                setEditing={(value) =>
                  setEditing((prev) => ({ ...prev, [subsection.id]: !!value }))
                }
                setCollapsed={(value) =>
                  setCollapsed((prev) => ({
                    ...prev,
                    [subsection.id]: !!value,
                  }))
                }
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default SubsectionsDragDrop
