import { createContext } from 'react'

import { ThreadProps } from './thread.types'

export const ThreadContext = createContext<ThreadProps>({
  questions: [],
  steps: {},
  currentStep: -1,
  isUndoDisabled: true,
  isRedoDisabled: true,
  storeToDb: async () => {},
  setCurrentStep: () => {},
  setSections: () => {},
  setSteps: () => {},
  setQuestions: () => {},
  handleUndo: () => {},
  handleRedo: () => {},
})
