'use client'

import { RiBook2Line, RiSparklingFill } from '@remixicon/react'
import { Button, Form, Input, Popover } from 'antd'
import { useEffect, useRef, useState } from 'react'

import useModalsState from '@/hooks/context/useModalsState'
import useThreadState from '@/hooks/context/useThreadState'

import ReferencesModal from '@/components/Chatbot/Output/Answer/ReferencesModal'

import { markdown } from '@/utils'
import { cn } from '@/utils/clsx'
import { companyEnhancePrompt } from '@/utils/prompts'

import LoadingScreen from '../LoadingScreen'
import ProgressButtons from '../ProgressButtons'
import lottieSearchingAnimation from '../../../../public/lottieSearching.json'

interface CompanyStepProps {
  getInnovations: (additionalInfo?: string) => Promise<void>
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  loading?: boolean
  goBack: () => void
}

const CompanyStep: React.FC<CompanyStepProps> = ({
  getInnovations,
  getAnswerWrapper,
  loading,
  goBack,
}) => {
  const [form] = Form.useForm()
  const { questions, steps, setCurrentStep, currentStep, setSteps } =
    useThreadState()
  const { toggleModal } = useModalsState()
  const [numQuestions, setNumQuestions] = useState<number>()
  const [enhancing, setEnhancing] = useState(false)
  const [open, setOpen] = useState(false)
  const answerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (steps[currentStep]?.numQuestions) {
      setNumQuestions(steps[currentStep].numQuestions ?? 0)
    }
  }, [])

  useEffect(() => {
    if (!loading && questions[questions.length - 1]?.messages[1]?.message) {
      setSteps({
        ...steps,
        [currentStep]: {
          numQuestions: numQuestions ?? 1,
        },
      })
      form.setFieldValue('additionalInfo', '')
      setEnhancing(false)
    }
  }, [loading, numQuestions])

  const finish = async (values: { [x: string]: string }) => {
    setSteps({
      ...steps,
      [currentStep + 1]: {
        numQuestions: 1,
        additionalInfo: values.additionalInfo,
      },
    })
    setCurrentStep(currentStep + 1)
    await getInnovations(
      `Additional information: ${values.additionalInfo ?? ''}`
    )
  }

  const enhance = async (values: { [x: string]: string }) => {
    setEnhancing(true)
    setOpen(false)
    await getAnswerWrapper(
      companyEnhancePrompt(
        questions[questions.length - 1]?.messages[1]?.message ?? '',
        values.additionalInfo ?? ''
      ),
      true
    )
    setNumQuestions((numQuestions ?? 1) + 1)
  }

  return (
    <Form
      form={form}
      onFinish={finish}
      autoComplete='off'
      className='flex h-full grow flex-col'
      layout='vertical'
      requiredMark='optional'
    >
      <div
        ref={answerRef}
        className='flex h-[calc(100vh-110px)] w-full overflow-y-auto p-2'
      >
        <div
          id='scroller'
          className={cn(
            'm-auto flex w-full min-h-full flex-col rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface',
            !questions[questions.length - 1]?.messages[1]?.message
              ? 'h-full'
              : ''
          )}
        >
          {questions[questions.length - 1]?.messages[1]?.message ? (
            <>
              <div className='markdown-answer grow break-words text-left text-sm sm:text-base'>
                {markdown(
                  questions[questions.length - 1]?.messages[1]
                    ?.message as string,
                  loading
                )}
              </div>
              {!loading && (
                <div className='mt-6 flex flex-col gap-4'>
                  <Form.Item
                    label='Additional information'
                    name='additionalInfo'
                    rules={[{ type: 'string' }]}
                  >
                    <Input.TextArea
                      rows={4}
                      disabled={enhancing}
                      placeholder='Add additional information about your company or any other relevant information.'
                    />
                  </Form.Item>
                  <ReferencesModal
                    documents={
                      questions[questions.length - 1]?.messages[1]?.documents ??
                      []
                    }
                  />
                </div>
              )}
              <div id='anchor' className='opacity-0'>
                a
              </div>
            </>
          ) : (
            <LoadingScreen
              lottieAnimation={lottieSearchingAnimation}
              text={[
                'Gathering background information about the company...',
                "Analyzing the company's mission, vision, and values...",
                "Exploring the company's product and service portfolio...",
                "Researching the company's market position and competitors...",
                'Identifying potential innovation areas aligned with the company...',
              ]}
              timeInterval={10000}
              infiniteLoader
            />
          )}
        </div>
      </div>
      <ProgressButtons
        containerRef={answerRef}
        changeIndicator={questions[questions.length - 1]?.messages[1]?.message}
        additionalButtons={
          questions[questions.length - 1]?.messages[1]?.message &&
          !loading && (
            <div className='flex justify-between gap-2'>
              <Button
                disabled={enhancing}
                onClick={() => toggleModal('references')}
                icon={<RiBook2Line className='size-5' />}
              >
                <span className='!hidden sm:!block'>References</span>
              </Button>
              <Popover
                trigger='click'
                className='cursor-pointer'
                overlayClassName='w-full max-w-md'
                open={open}
                onOpenChange={setOpen}
                content={
                  <Form
                    autoComplete='off'
                    className='flex w-full flex-col items-end'
                    layout='vertical'
                    onFinish={enhance}
                  >
                    <Form.Item
                      name='additionalInfo'
                      rules={[{ type: 'string' }]}
                      className='w-full'
                    >
                      <Input.TextArea
                        rows={5}
                        placeholder='How would you like to enhance this information?'
                        className='w-full'
                      />
                    </Form.Item>
                    <Form.Item className='m-0'>
                      <Button
                        className='flex items-center'
                        htmlType='submit'
                        type='primary'
                        icon={<RiSparklingFill className='size-5' />}
                      >
                        Enhance
                      </Button>
                    </Form.Item>
                  </Form>
                }
              >
                <Button
                  className='flex items-center'
                  icon={<RiSparklingFill className='size-5' />}
                >
                  <span className='!hidden sm:!block'>Enhance</span>
                </Button>
              </Popover>
            </div>
          )
        }
        goBack={goBack}
        disabledBack={
          enhancing ||
          !questions[questions.length - 1]?.messages[1]?.message ||
          loading
        }
        disabledNext={
          enhancing ||
          !questions[questions.length - 1]?.messages[1]?.message ||
          loading
        }
      />
    </Form>
  )
}

export default CompanyStep
