'use client'

import { RiDeleteBinLine, RiEditLine, RiSparklingFill } from '@remixicon/react'
import { Button, Form, Input, Popconfirm, Popover } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'

import useThreadState from '@/hooks/context/useThreadState'
import useAgents from '@/hooks/useAgents'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'
import { getContext } from '@/service/Chatbot'
import { markdown, sectionToString } from '@/utils'
import { cn } from '@/utils/clsx'
import { createTmpMessage, updateGraphsOfSection } from '@/utils/thread'

import { IQuestion, IQuestionPost } from '@/types/chatbot'
import { Section, Subsection } from '@/types/document'

interface GraphContainerProps {
  graph: string
  index: number
  section: Section
  subsection?: Subsection
  isSubsection?: boolean
}

const GraphContainer: React.FC<GraphContainerProps> = ({
  graph,
  index,
  section,
  subsection,
  isSubsection,
}) => {
  const { sections, setSections, questions, setSteps, currentStep } =
    useThreadState()
  const { selectedAgent } = useAgents()
  const [instructions, setInstructions] = useState('')
  const [updating, setUpdating] = useState(false)
  const [open, setOpen] = useState(false)

  const handleDeleteGraph = (index: number) => {
    setSections(
      ({ sections: prevSections, history: prevHistory, historyIndex }) => {
        const newSections = prevSections?.map((s) => {
          if (s.id === section.id) {
            if (subsection) {
              const newSubsections =
                s.subsections?.map((sub) => {
                  if (sub.id === subsection.id) {
                    return {
                      ...sub,
                      graph: sub.graph?.filter((_, i) => i !== index),
                    }
                  }
                  return sub
                }) ?? []
              return {
                ...s,
                subsections: newSubsections,
              }
            } else {
              return {
                ...s,
                graph: s.graph?.filter((_, i) => i !== index),
              }
            }
          }
          return s
        })
        let newHistory = prevHistory
        if (newSections) {
          newHistory = [
            ...(prevHistory.slice(0, (historyIndex ?? 0) + 1) ?? []),
            newSections,
          ]
        }
        setSteps((prevSteps) => ({
          ...prevSteps,
          [currentStep]: {
            ...prevSteps[currentStep],
            sections: newSections,
          },
        }))
        return {
          sections: newSections,
          history: newHistory,
          historyIndex: newHistory.length - 1,
        }
      }
    )
  }

  const handleEditGraph = async (index: number) => {
    setUpdating(true)
    setOpen(false)
    try {
      const context: IQuestion = createTmpMessage(
        `Current document sections:\n${sections?.map((s) => sectionToString(s)).join('\n')}`,
        selectedAgent.id
      )
      const { messages } = getContext([...(questions ?? []), context])

      const graph = (subsection ? subsection.graph : section.graph)?.[index]

      if (!graph) {
        return
      }

      const question: IQuestionPost = {
        role: 'user',
        content: [
          {
            type: 'text',
            text: `Please update the given graph image according to this instructions:${instructions}`,
          },
          {
            type: 'image_url',
            image_url: {
              url: graph,
            },
          },
        ],
      }

      const config = {
        method: 'post',
        withCredentials: true,
        ...configHeader,
        data: JSON.stringify({
          question,
          messages,
        }),
      }
      const res = await axios(`${API_URL}/v2/chatbot/graph-update`, config)

      updateGraphsOfSection(
        setSections,
        setSteps,
        isSubsection,
        subsection ?? section,
        res.data.graph,
        index
      )

      setInstructions('')
    } catch (error) {
      console.error(error)
    }
    setUpdating(false)
  }

  return (
    <div
      className={cn(
        'relative rounded-lg p-3 hover:bg-primary/5',
        updating ? 'pointer-events-none' : ''
      )}
    >
      <div className='absolute right-3 flex gap-1'>
        <Popover
          trigger='click'
          overlayClassName='w-full max-w-sm'
          open={open}
          onOpenChange={setOpen}
          content={
            <Form
              autoComplete='off'
              className='flex w-full flex-col items-end'
              layout='vertical'
            >
              <Form.Item
                name='instructions'
                rules={[{ type: 'string' }]}
                className='w-full'
              >
                <Input.TextArea
                  value={instructions}
                  placeholder='Provide specific instructions on how to update this graph'
                  onChange={(e) => setInstructions(e.target.value)}
                  rows={4}
                  required
                />
              </Form.Item>
              <Form.Item className='m-0'>
                <Button
                  className='flex items-center'
                  htmlType='submit'
                  type='primary'
                  onClick={() => handleEditGraph(index)}
                  icon={<RiSparklingFill className='size-5' />}
                >
                  Update
                </Button>
              </Form.Item>
            </Form>
          }
        >
          <Button loading={updating} icon={<RiEditLine className='size-5' />}>
            {updating ? 'Updating...' : null}
          </Button>
        </Popover>
        <Popconfirm
          trigger='click'
          title='Are you sure you want to delete this graph?'
          okText='Yes'
          cancelText='No'
          onConfirm={() => handleDeleteGraph(index)}
        >
          <Button icon={<RiDeleteBinLine className='size-5' />} />
        </Popconfirm>
      </div>
      <div className={updating ? 'opacity-60' : ''}>
        {markdown(
          `\n![Graph-${section.id}-${subsection?.id ?? ''}-${index}](${graph})`
        )}
      </div>
    </div>
  )
}

export default GraphContainer
