import { dateFormat, datetimeFormat } from '@/branding-config'

export const applicationGuidancePrompt = () =>
  `Provide Application Guidance and Next Steps
Provide an application link for the patent category that was chosen and specific, detailed instructions for applying as well as a list of next steps that are specific to the patent. Avoid general recommendations like 'check the website for details', and 'see the application instructions' as these do not illustrate enough specific details to be useful. Use specific information, dates, and information instead.

The report should be very long and very detailed.
FOR DATES ALWAYS USE AMERICAN DATE FORMAT: ${dateFormat}
FOR DATE TIME ALWAYS USE AMERICAN DATE TIME FORMAT: ${datetimeFormat}
YOU MUST RESPOND IN MARKDOWN FORMAT.
USE INLINE CITATIONS IN TEXT FOR ALL FACTS, DATA, AND INFORMATION THAT IS RETRIEVED FROM CONTEXT DOCUMENTS, MESSAGE HISTORY OR WEB SEARCHES!!!
THERE MUST BE AT LEAST 1 CITATION PER PARAGRAPH.`
