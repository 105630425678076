'use client'

import {
  RiArrowGoBackLine,
  RiArrowGoForwardLine,
  RiCollapseVerticalLine,
  RiExpandVerticalLine,
} from '@remixicon/react'
import { Button, message, Tooltip } from 'antd'
import axios from 'axios'
import { jsonrepair } from 'jsonrepair'
import { useEffect, useRef, useState } from 'react'

import useDrawerState from '@/hooks/context/useDrawerState'
import useThreadState from '@/hooks/context/useThreadState'
import useAgents from '@/hooks/useAgents'
import useAuth from '@/hooks/useAuth'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'
import { countWords, generateUUID } from '@/utils'
import { cn } from '@/utils/clsx'
import { enhance } from '@/utils/thread'

import AddSectionButton from './AddSectionButton'
import GoogleDocsViewer from './GoogleDocsViewer'
import SectionsDragDrop from './SectionsDragDrop'
import LoadingScreen from '../LoadingScreen'
import ProgressButtons from '../ProgressButtons'
import lottieGeneratingDocument from '../../../../public/lottieGeneratingDocument.json'
import lottieScanDocument from '../../../../public/lottieScanDocument.json'

import { Section } from '@/types/document'
import { EnhanceType } from '@/types/thread'

interface DraftEditorProps {
  goBack: () => void
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  loading?: boolean
}

const DraftEditor: React.FC<DraftEditorProps> = ({
  getAnswerWrapper,
  loading,
  goBack,
}) => {
  const { user } = useAuth()
  const {
    setCurrentStep,
    currentStep,
    steps,
    questions,
    sections,
    setSections,
    setSteps,
    setQuestions,
    isUndoDisabled,
    isRedoDisabled,
    handleUndo,
    handleRedo,
  } = useThreadState()
  const { selectedConversation } = useDrawerState()
  const { selectedAgent } = useAgents()
  const [editing, setEditing] = useState<{ [key: string]: boolean }>()
  const [enhancing, setEnhancing] = useState<{ [key: string]: boolean }>()
  const [collapsed, setCollapsed] = useState<{ [key: string]: boolean }>()
  const [documentUrl, setDocumentUrl] = useState<string>()
  const [generatingDocument, setGeneratingDocument] = useState<boolean>(false)
  const [initialSectionsStatus, setInitialSectionsStatus] = useState<
    'enhancing' | 'enhanced' | 'enhancingTwice' | 'enhancedTwice'
  >()
  const [finishedSteps, setFinishedSteps] = useState<number>(0)
  const answerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setDocumentUrl(steps[currentStep]?.documentUrl)
  }, [steps[currentStep]?.documentUrl])

  useEffect(() => {
    setInitialSectionsStatus(steps[currentStep]?.initialSectionsStatus)
  }, [steps[currentStep]?.initialSectionsStatus])

  useEffect(() => {
    if (sections && sections.length > 0) {
      return
    }

    if (!loading) {
      const text = questions[questions.length - 1]?.messages[1]?.message
      if (text && text !== '') {
        try {
          const json = JSON.parse(
            jsonrepair(text.replaceAll('```json', '').replaceAll('```', ''))
          ) as { data: Section[] }
          const newSections = json.data.map((section) => ({
            id: generateUUID(),
            title: section?.title ?? '',
            text: section?.text ?? '',
            subsections:
              section?.subsections?.map(
                (subsection: { title: string; text: string }) => ({
                  id: generateUUID(),
                  title: subsection.title,
                  text: subsection.text,
                })
              ) ?? [],
          }))
          let filteredNewSections = newSections.filter(
            (section) => section.title && section.title !== ''
          )

          if (!filteredNewSections || filteredNewSections.length === 0) {
            throw new Error('An error occurred. Please try again.')
          }
          if (filteredNewSections.length > 15) {
            filteredNewSections = filteredNewSections
              .filter((section) => section.text && section.text !== '')
              .slice(0, 15)
          }

          setSteps({
            ...steps,
            [currentStep]: {
              ...steps[currentStep],
              numQuestions: 1,
            },
          })

          setSections({
            sections: filteredNewSections,
            history: [filteredNewSections],
            historyIndex: 0,
          })
        } catch (error) {
          console.error(error)
          message.error('An error occurred. Please try again.')
          setCurrentStep(currentStep - 1)
          setQuestions(questions.slice(0, -1))
        }
      }
    }
  }, [
    loading,
    questions[questions.length - 1]?.messages[1]?.message,
    steps[currentStep]?.sections,
  ])

  const enhanceAllSections = async () => {
    if (
      sections &&
      sections.length > 0 &&
      !initialSectionsStatus &&
      !steps[currentStep]?.initialSectionsStatus &&
      finishedSteps === 0
    ) {
      setInitialSectionsStatus('enhancingTwice')
      setFinishedSteps((finished) => finished + 1)
      const enhanceSubsetOfSections = async (sections: Section[]) => {
        for (const section of sections) {
          // Enhance with data
          let enhancedSection = await enhance(
            setSections,
            setSteps,
            questions,
            selectedAgent.id,
            section,
            EnhanceType.LONGER,
            selectedConversation,
            user,
            sections
          )
          setFinishedSteps((finished) => finished + 1)

          // Enhance with citations
          enhancedSection = await enhance(
            setSections,
            setSteps,
            questions,
            selectedAgent.id,
            enhancedSection,
            EnhanceType.CITE,
            selectedConversation,
            user,
            sections
          )
          setFinishedSteps((finished) => finished + 1)
        }
      }
      // split sections into chunks of 4
      const chunkSize = 4
      const promises = []
      for (let i = 0; i < sections.length; i += chunkSize) {
        const sectionsChunk = sections.slice(i, i + chunkSize)
        // create a new promise for each chunk
        promises.push(enhanceSubsetOfSections(sectionsChunk))
      }
      // wait for all promises to resolve
      await Promise.all(promises)

      setSteps((steps: any) => ({
        ...steps,
        [currentStep]: {
          ...steps[currentStep],
          numQuestions: 1,
          initialSectionsStatus: 'enhancedTwice',
        },
      }))
      setInitialSectionsStatus('enhancedTwice')
    }
  }

  useEffect(() => {
    enhanceAllSections()

    if (initialSectionsStatus === 'enhancedTwice') {
      setSteps((steps: any) => ({
        ...steps,
        [currentStep]: {
          ...steps[currentStep],
          numQuestions: 1,
          initialSectionsStatus: 'enhancedTwice',
        },
      }))
    }
  }, [sections, initialSectionsStatus])

  const generateDocument = async () => {
    setGeneratingDocument(true)
    try {
      const res = await axios(`${API_URL}/google-doc/generate-document`, {
        method: 'post',
        withCredentials: true,
        ...configHeader,
        data: JSON.stringify({
          sections: sections,
          user: user?.email,
        }),
      })

      setSteps({
        ...steps,
        [currentStep]: {
          ...steps[currentStep],
          documentUrl: res.data.url,
        },
      })
      setDocumentUrl(res.data.url)
    } catch (error) {
      console.error(error)
      message.error('Failed to generate the patent application document.')
      setGeneratingDocument(false)
    }
  }

  const collapseAll = () => {
    const newCollapsed = sections?.reduce(
      (acc, section) => ({ ...acc, [section.id]: true }),
      {}
    )
    setCollapsed(newCollapsed)
  }

  if (documentUrl) {
    return (
      <GoogleDocsViewer
        documentUrl={documentUrl}
        setDocumentUrl={setDocumentUrl}
        setGeneratingDocument={setGeneratingDocument}
        getAnswerWrapper={getAnswerWrapper}
      />
    )
  }

  const handleCountWords = () => {
    let numWords = 0
    for (const section of sections ?? []) {
      const sectionsWords = countWords(`${section.title} ${section.text}`)
      const subsectionsWords = countWords(
        section.subsections?.reduce(
          (acc, subsection) => `${acc} ${subsection.title} ${subsection.text}`,
          ''
        ) ?? ''
      )
      numWords += sectionsWords + subsectionsWords
    }
    return numWords
  }

  return (
    <>
      {!generatingDocument &&
        sections &&
        !loading &&
        initialSectionsStatus === 'enhancedTwice' && (
          <div className='flex w-full items-center justify-between gap-2 bg-surface px-4 pb-3 text-on-surface transition-all dark:bg-dark-surface dark:text-dark-on-surface'>
            <div className='flex items-center gap-2'>
              <Button
                className='w-fit self-end'
                icon={<RiExpandVerticalLine className='size-4' />}
                onClick={() => setCollapsed({})}
              >
                <span className='!hidden lg:!flex'>Expand all sections</span>
              </Button>
              <Button
                className='w-fit self-end'
                icon={<RiCollapseVerticalLine className='size-4' />}
                onClick={collapseAll}
              >
                <span className='!hidden lg:!flex'>Collapse all sections</span>
              </Button>
              <AddSectionButton setEditing={setEditing} />
              <Tooltip title='Undo'>
                <Button
                  icon={
                    <RiArrowGoBackLine
                      className={cn(
                        'size-5',
                        isUndoDisabled ? 'opacity-40' : ''
                      )}
                    />
                  }
                  onClick={handleUndo}
                  className={cn(
                    'flex items-center',
                    isUndoDisabled
                      ? 'grayscale-[0.8] text-opacity-20 pointer-events-none'
                      : ''
                  )}
                />
              </Tooltip>
              <Tooltip title='Redo'>
                <Button
                  icon={
                    <RiArrowGoForwardLine
                      className={cn(
                        'size-5',
                        isRedoDisabled ? 'opacity-40' : ''
                      )}
                    />
                  }
                  onClick={handleRedo}
                  className={cn(
                    'flex items-center',
                    isRedoDisabled
                      ? 'grayscale-[0.8] text-opacity-20 pointer-events-none'
                      : ''
                  )}
                />
              </Tooltip>
            </div>
            <Tooltip title='Total number of words in the patent application draft.'>
              <div className='text-xs opacity-70'>
                {handleCountWords()} words
              </div>
            </Tooltip>
          </div>
        )}
      <div
        ref={answerRef}
        className={cn(
          'overflow-y-auto flex w-full p-2',
          !sections ||
            loading ||
            initialSectionsStatus !== 'enhancedTwice' ||
            generatingDocument
            ? 'h-[calc(100vh-62px)]'
            : 'h-[calc(100vh-106px)]'
        )}
      >
        <div
          className={cn(
            'relative m-auto min-h-full flex w-full flex-col gap-6 rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface',
            !sections ||
              loading ||
              initialSectionsStatus !== 'enhancedTwice' ||
              generatingDocument
              ? 'h-auto'
              : ''
          )}
        >
          {!sections || loading || initialSectionsStatus !== 'enhancedTwice' ? (
            <LoadingScreen
              finishedSteps={finishedSteps}
              allSteps={(sections?.length ?? 0) * 2 + 1}
              lottieAnimation={lottieScanDocument}
              text={[
                'Reviewing patent eligibility and legal requirements...',
                'Gathering detailed descriptions and technical drawings...',
                "Documenting the invention's development process...",
                'Highlighting the unique features and benefits...',
                'Drafting clear and concise patent claims...',
                'Aligning the application with patent office guidelines...',
                'Ensuring compliance with formatting and submission rules...',
                'Preparing supporting documents and prior art references...',
                'Revising the draft for clarity and precision...',
                'Conducting a final review before application submission...',
              ]}
              timeInterval={20000}
            />
          ) : generatingDocument ? (
            <LoadingScreen
              lottieAnimation={lottieGeneratingDocument}
              text={[
                'Compiling all content into a single document...',
                'Structuring sections for logical flow and clarity...',
                'Adding visuals, tables, and supporting data...',
                'Formatting the document for a polished presentation...',
                'Finalizing the document for download and review...',
              ]}
              timeInterval={10000}
              infiniteLoader
            />
          ) : (
            <>
              <div className='m-auto flex w-full grow flex-col justify-between gap-4'>
                <SectionsDragDrop
                  enhancing={enhancing}
                  setEnhancing={setEnhancing}
                  editing={editing}
                  setEditing={setEditing}
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <ProgressButtons
        containerRef={answerRef}
        changeIndicator={sections}
        goBack={goBack}
        goNext={generateDocument}
        disabledBack={generatingDocument}
        disabledNext={
          !sections ||
          loading ||
          initialSectionsStatus !== 'enhancedTwice' ||
          generatingDocument ||
          (enhancing &&
            Object.values(enhancing).some((isEnhancing) => isEnhancing))
        }
      />
    </>
  )
}

export default DraftEditor
